document.addEventListener('DOMContentLoaded', function () {
    initImageSizer()
    initVideoSlider()
    initOffCanvas()
    animateInViewIntersectionObs()
 });

 function initImageSizer() {
    var images = document.querySelectorAll('.auto-sizing')
    if (images.length === 0) return;
    images.forEach(function(img) {
        var windowWidth = window.innerWidth;
        var imgWidth = img.clientWidth;
        var imgViewportWidth = Math.floor((imgWidth / windowWidth) * 100) + "vw";
        img.sizes = imgViewportWidth;
    })
 }

 function initVideoSlider() {
    var video = document.querySelector('.large-slider__inner iframe')
    if (!video) return;
    coverViewport(video, true);
    window.addEventListener('resize', function () {
        coverViewport(video, true)
    });
    
    function coverViewport(element, scaleToParent) {
        if (typeof scaleToParent === 'undefined') { scaleToParent = false; }

        var viewportWidth, viewportHeight;
        if (scaleToParent) {
            viewportWidth = element.parentElement.clientWidth;
            viewportHeight = element.parentElement.clientHeight;
        } else {
            viewportWidth = window.innerWidth;
            viewportHeight = window.innerHeight;
        }

        var elementWidth = element.getAttribute('width');
        var elementHeight = element.getAttribute('height');

        // Aspect ratio = height / width
        var viewportRatio = viewportHeight / viewportWidth;
        var videoRatio = elementHeight / elementWidth;

        // console.log('viewportHeigt: ', viewportHeight )
        // console.log('viewportWidth: ', viewportWidth )
        // console.log('Viewport: ', viewportRatio, ' Video: ', videoRatio);


        var newWidth = (elementWidth / elementHeight) * viewportHeight
        var newHeight = (elementHeight / elementWidth) * viewportWidth;
        var newTop = 0;

        if (viewportRatio > videoRatio) {
            // set video height to the viewport, and extend the video beyond the viewport width, i.e. portrait viewport
            // console.log('crop left and right');
            element.style.height = viewportHeight +'px';
            element.style.width = newWidth +'px';
            element.style.left = '-' + (newWidth - viewportWidth) / 2 +'px';
            element.style.top = '0';
        } else if (viewportRatio < videoRatio) {
            // set video width to the viewport, and extend the video beyond the viewport height, i.e. landscape viewport
            // console.log('crop top and bottom');
            element.style.width = viewportWidth +'px';
            element.style.height = newHeight +'px';
            element.style.left = '0';
            element.style.top = '-' + (newHeight - viewportHeight) / 2 +'px';
        } else {
            // set the video width AND height to the viewport size, i.e. viewport is the same ratio as the video
            // console.log('no crop');
            element.style.height = viewportHeight +'px';
            element.style.width = viewportWidth +'px';
            element.style.left = '0';
            element.style.top = '0';
        }
    }
 }

 function initOffCanvas () {
    var menuContainer = document.querySelector('.off-canvas');
    var menuToggleBtns = document.querySelectorAll(
        '.off-canvas__open, .off-canvas__close'
    );
    
    var bookingContainer = document.querySelector('#booking');
    var bookingToggleBtns = document.querySelectorAll(
        '.booking-btn, .booking__close, a[href="#bookTable"]'
    );
    var offCanvasBookingBtn = document.querySelector('.offCanvas-book-now')



    var states = {
        isActiveMenu: false,
        isActiveBooking: false,
        toggleMenu: function () {this.isActiveMenu = !this.isActiveMenu},
        toggleBooking: function () {this.isActiveBooking = !this.isActiveBooking}
    }

    handleOffCanvasMenu(menuContainer, menuToggleBtns, states)
    handleOffCanvasBooking(bookingContainer, bookingToggleBtns, states)
    // handleOffCanvasBooking(bookingContainer, bookingOpenLinks, states)

    var argsHandleCanBtn = {
        offCanvasBookingBtn: offCanvasBookingBtn, 
        states: states,
        bookingContainer: bookingContainer,
        menuContainer: menuContainer
    }
    handleOffCanvasBtn(argsHandleCanBtn)



    function handleOffCanvasMenu (container, toggleBtns, states) {
        toggleBtns.forEach( function (btn) {
            btn.addEventListener('click', function () {
                states.toggleMenu()
                handleToggle(states.isActiveMenu, container)
            });
        });
    }

    function handleOffCanvasBooking (container, toggleBtns, states) {
        var frames = document.querySelectorAll('#booking iframe')
        toggleBtns.forEach( function (btn) {
            btn.addEventListener('click', function () {
                states.toggleBooking()
                handleToggle(states.isActiveBooking, container)
                if (frames.length > 0 ) {
                    handleIframes(frames)
                }
            });
        });
    }

    function handleOffCanvasBtn(args) {
        args.offCanvasBookingBtn.addEventListener('click', function() {
            args.states.toggleMenu()
            args.states.toggleBooking()
            handleToggle(args.states.isActiveBooking, args.bookingContainer)
            handleToggle(args.states.isActiveMenu, args.menuContainer)
        })
    }

    var handleToggle = function (state, container) {
        if (!state) {
            container.style.transform = 'translateX(-100%)';
        } else {
            container.style.transform = 'translateX(0)';
        }
    };

    function handleIframes(frames) {
        frames.forEach(function(frame) {   
            if (typeof frame.dataset.src !== 'undefined' && frame.src === '' ) {
                frame.src = frame.dataset.src
            }
        } )
    }
}


function animateInViewIntersectionObs() {
	var elements = document.querySelectorAll('[data-animate-in-view]')	
	var options = {
		root: null,
		rootMargin: "0px",
		threshold: 0,
		};
	
	function callbackObserver(entries) {
		entries.forEach(function(entry) {
			var element = entry.target;
			
			if (!entry.isIntersecting) return;
			
		
			element.classList.add("in-view")
			element.classList.add(element.dataset.animateInView)
			observer.unobserve(element)
		});
	}
	
	function setObserver(elements) {
		elements.forEach(function (el) {
			observer.observe(el)   
		})
	}

	var observer = new IntersectionObserver(callbackObserver, options);
	setObserver(elements)
}